import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer id="contact" className="bg-dark text-white mt-5 p-3 text-center">
      <Container>
        <div className="footer-col">
          <div md="4">
            <h5>Contact Us</h5>
            <p>
              <span>Email:</span> partners@echoedgecreations.com
            </p>
            <p>
              <span>Phone:</span> +44 131 3560923
            </p>
          </div>

          <div className="footer-rights">
            <p>© 2024 EchoEdge Creations. All Rights Reserved.</p>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
