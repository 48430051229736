import React from "react";
import NavBar from "./components/Navbar";
import LandingPage from "./components/Landing";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <NavBar />
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;
