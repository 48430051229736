import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/logo.png";

function NavBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavLinkClick = (id) => {
    setIsNavOpen(false); // Close the navbar
    const element = document.getElementById(id); // Find the section by ID
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Smooth scroll
      window.history.pushState(null, null, window.location.pathname); // Remove the hash from the URL
    }
  };

  return (
    <Navbar
      bg="light"
      variant="light"
      expand="lg"
      sticky="top"
      className=""
      expanded={isNavOpen}
      onToggle={() => setIsNavOpen(!isNavOpen)}
    >
      <Container>
        <Navbar.Brand onClick={() => handleNavLinkClick("home")}>
          <img src={logo} alt="Brand Logo" className="navbar-logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              className="nav-link"
              onClick={() => handleNavLinkClick("contact")}
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              className="nav-link"
              onClick={() => handleNavLinkClick("services")}
            >
              Portfolio
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
