import React, { useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min"; //Import Bootstrap for styles
import { Container, Row, Col } from "react-bootstrap";
import "../index.css"; //Extra styles

//Media for Video Display
import videoSample from "../assets/corp-sample-1.mp4";
import videoSample2 from "../assets/corp-sample-2.mp4";
import videoSample3 from "../assets/corp-sample-3.mp4";
import videoSample4 from "../assets/corp-sample-4.mp4";
import videoSample5 from "../assets/corp-sample-5.mp4";
import videoSample6 from "../assets/corp-sample-6.mp4";
import thumbnail1 from "../assets/thumbnail-1.png";
import thumbnail2 from "../assets/thumbnail-2.png";
import thumbnail3 from "../assets/thumbnail-3.png";
import thumbnail4 from "../assets/thumbnail-4.png";
import thumbnail5 from "../assets/thumbnail-5.png";
import thumbnail6 from "../assets/thumbnail-6.png";

//Media for Brochures Display

//Brochure 1
import brochure1 from "../assets/brochures/brochure-1/sample-brochure-1.pdf";
import brochureThumbnail1 from "../assets/brochures/brochure-1/brochure-thumbnail-1.png";
import brochure1Page1 from "../assets/brochures/brochure-1/brochure-1-page1.png";
import brochure1Page2 from "../assets/brochures/brochure-1/brochure-1-page2.png";

//Brochure 2
import brochure2 from "../assets/brochures/brochure-2/sample-brochure-2.pdf";
import brochureThumbnail2 from "../assets/brochures/brochure-2/brochure-thumbnail-2.png";
import brochure2Page1 from "../assets/brochures/brochure-2/brochure-2-page1.png";
import brochure2Page2 from "../assets/brochures/brochure-2/brochure-2-page2.png";

//Brochure 3
import brochure3 from "../assets/brochures/brochure-3/sample-brochure-3.pdf";
import brochureThumbnail3 from "../assets/brochures/brochure-3/brochure-thumbnail-3.png";
import brochure3Page1 from "../assets/brochures/brochure-3/brochure-3-page1.png";
import brochure3Page2 from "../assets/brochures/brochure-3/brochure-3-page2.png";

//Brochure 4
import brochureThumbnail4 from "../assets/brochures/brochure-4/brochure-thumbnail-4.png";
import brochure4Page1 from "../assets/brochures/brochure-4/brochure-4-page1.png";
import brochure4Page2 from "../assets/brochures/brochure-4/brochure-4-page2.png";

//Brochure 5
import brochureThumbnail5 from "../assets/brochures/brochure-5/brochure-thumbnail-5.png";
import brochure5Page1 from "../assets/brochures/brochure-5/brochure-5-page1.png";
import brochure5Page2 from "../assets/brochures/brochure-5/brochure-5-page2.png";

//Brochure 6
import brochureThumbnail6 from "../assets/brochures/brochure-6/brochure-thumbnail-6.png";
import brochure6Page1 from "../assets/brochures/brochure-6/brochure-6-page1.png";
import brochure6Page2 from "../assets/brochures/brochure-6/brochure-6-page2.png";

function LandingPage() {
  useEffect(() => {
    try {
      //Check if compatible for Observer
      if (!("IntersectionObserver" in window)) {
        throw new Error(
          "IntersectionObserver is not supported in this browser."
        );
      }
      //Create Observer effect for each element
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show");
            } else {
              entry.target.classList.remove("show");
            }
          });
        },
        {
          threshold: 0.1,
        }
      );

      const hiddenElements = document.querySelectorAll(".hidden");
      hiddenElements.forEach((el) => observer.observe(el));

      return () => {
        hiddenElements.forEach((el) => observer.unobserve(el));
      };
    } catch (error) {
      console.error("An error occurred in the IntersectionObserver:", error);
    }
  }, []);

  const Hero = () => {
    window.addEventListener("scroll", () => {
      const scrolled = window.scrollY;
      document.querySelector(
        ".hero-section"
      ).style.backgroundPosition = `center ${scrolled * 0.5}px`;
    });

    const handleNavLinkClick = (id) => {
      const element = document.getElementById(id); // Find the section by ID
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Smooth scroll
        window.history.pushState(null, null, window.location.pathname); // Remove the hash from the URL
      }
    };

    return (
      <div className="hero-container">
        <section
          id="home"
          className="hero-section d-flex align-items-center justify-content-center text-center text-light hidden"
        >
          <Container>
            <Row>
              <Col>
                <Container>
                  <h1>
                    Premium Video Production and Corporate Brochure Design
                    Solutions
                  </h1>
                  <p>Elevate your brand with our professional services</p>
                  <button
                    type="button"
                    className="btn btn-dark btn-lg"
                    onClick={() => handleNavLinkClick("services")}
                  >
                    Explore Our Services
                  </button>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  };

  const VideoSection = () => {
    return (
      <>
        <Row className="about-section hidden">
          <Col>
            <h2>
              Our Video Production <p>Services</p>
            </h2>
          </Col>
          <Col>
            <Container className="about-container">
              <p>
                We offer high-quality, affordable video production services
                designed to meet your corporate needs, from marketing videos to
                internal communications. Our hassle-free process allows us to
                complete videos externally with minimal input from you, ensuring
                professional, attention-grabbing content that delivers results
                without disrupting your workflow.
              </p>
              <Row>
                <Col>
                  <Row>
                    <h3>Marketing Videos</h3>
                  </Row>
                  <Row>
                    <p>
                      Our marketing videos are crafted to captivate your
                      audience and promote your brand effectively.
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h3>Internal Communications</h3>
                  </Row>
                  <Row>
                    <p>
                      We create engaging videos for internal communications to
                      keep your team informed and motivated.
                    </p>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Container className="featured-vid">
          <Row className="pb-5 hidden">
            <Col className="pb-5 featured-vid">
              <h1 className="text-center pb-3">Featured Video Projects</h1>
              <p className="text-center">
                Explore our stunning video productions that elevate your brand’s
                story.
              </p>
            </Col>

            <div className="featured-content-box">
              <div className="content-inner-container">
                <h2 className="pt-3">Product</h2>
                <h2>Advertisement</h2>
                <div className="business-video-content">
                  <div className="p-2">
                    <video
                      src={videoSample}
                      poster={thumbnail1}
                      className="d-block w-100"
                      alt="Featured Video Sample"
                      controls
                    ></video>
                  </div>
                  <div className="p-2">
                    <video
                      src={videoSample3}
                      poster={thumbnail3}
                      className="d-block w-100"
                      alt="Featured Video Sample"
                      controls
                    ></video>
                  </div>
                </div>
                <h2 className="pt-3">Business</h2>
                <h2>Advertisement</h2>
                <div className="business-video-content">
                  <div className="p-2">
                    <video
                      src={videoSample2}
                      poster={thumbnail2}
                      className="d-block w-100"
                      alt="Featured Video Sample"
                      controls
                    ></video>
                  </div>
                  <div className="p-2">
                    <video
                      src={videoSample4}
                      poster={thumbnail4}
                      className="d-block w-100"
                      alt="Featured Video Sample"
                      controls
                    ></video>
                  </div>
                </div>
                <h2 className="pt-3">Digital Branding</h2>
                <div className="business-video-content">
                  <div className="p-2">
                    <video
                      src={videoSample5}
                      poster={thumbnail5}
                      className="d-block w-100"
                      alt="Featured Video Sample"
                      controls
                    ></video>
                  </div>
                  <div className="p-2">
                    <video
                      src={videoSample6}
                      poster={thumbnail6}
                      className="d-block w-100"
                      alt="Featured Video Sample"
                      controls
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  const BrochureSection = () => {
    const brochures = [
      {
        thumbnail: brochureThumbnail1,
        pdf: brochure1,
        page1: brochure1Page1,
        page2: brochure1Page2,
        title: "Service Brochures",
      },
      {
        thumbnail: brochureThumbnail6,
        page1: brochure6Page1,
        page2: brochure6Page2,
        title: "",
      },
      {
        thumbnail: brochureThumbnail2,
        pdf: brochure2,
        page1: brochure2Page1,
        page2: brochure2Page2,
        title: "Technology Brochures",
      },
      {
        thumbnail: brochureThumbnail3,
        pdf: brochure3,
        page1: brochure3Page1,
        page2: brochure3Page2,
        title: "Industrial Brochures",
      },
      {
        thumbnail: brochureThumbnail4,
        page1: brochure4Page1,
        page2: brochure4Page2,
        title: "Property Brochures",
      },
      {
        thumbnail: brochureThumbnail5,
        page1: brochure5Page1,
        page2: brochure5Page2,
        title: "",
      },
    ];

    return (
      <>
        <Row className="about-section hidden">
          <Col>
            <h2>
              Our Custom Brochure <p>Services</p>
            </h2>
          </Col>
          <Col>
            <Container className="about-container">
              <p>
                We provide a comprehensive range of brochure design services
                tailored to enhance your corporate communication and branding
                efforts. Whether you need a sleek marketing brochure or an
                informative communication piece, we are here for you.
              </p>
              <Row>
                <Col>
                  <Row>
                    <h3>Marketing Brochures</h3>
                  </Row>
                  <Row>
                    <p>
                      Our marketing brochures are meticulously designed to grab
                      attention and effectively convey your brand message,
                      helping you to engage potential customers and drive
                      business growth.
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h3>Communication Brochures</h3>
                  </Row>
                  <Row>
                    <p>
                      We design clear and compelling communication brochures
                      that keep your team informed, aligned, and motivated,
                      ensuring that everyone is on the same page.
                    </p>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Container className="pb-5">
          <Row className="featured-vid">
            <h1 className="text-center p-4">Featured Brochures</h1>
            <div className="featured-brochure-box">
              <div className="brochure-box-container">
                {brochures.map((brochure, index) => (
                  <div key={index} className="outer-brochure-box">
                    <h2>{brochure.title}</h2>
                    <div className="brochure-box">
                      <img
                        src={brochure.thumbnail}
                        alt="Brochure Thumbnail"
                        className="brochure-thumbnail"
                      />

                      <img
                        src={brochure.page1}
                        className="brochure-thumbnail"
                        alt="Brochure Page"
                      />
                      <img
                        src={brochure.page2}
                        className="brochure-thumbnail"
                        alt="Brochure Page"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <div>
      <Hero></Hero>
      <Container id="services">
        <VideoSection></VideoSection>
        <BrochureSection></BrochureSection>
      </Container>
    </div>
  );
}

export default LandingPage;
